<template>
  <div class="bg">
    <div class="top" v-if="user_type == 1">
      <div class="top_1">
        <img src="https://img.yzcdn.cn/vant/apple-1.jpg" alt="" class="fl" />
        <div class="fl">
          <p><span class="f18">王涛</span> <span class="f14">主任医师</span></p>
          <p><span class="f12">中国人民解放军总医院301医院</span></p>
          <p><span class="tag">呼吸科</span></p>
        </div>
      </div>
      <div class="top_2">
        <div class="fl bor">
          <p class="f16">4</p>
          <div>在线问诊人数</div>
        </div>

        <div class="fl">
          <p class="f16">4</p>
          <div>问诊总人数</div>
        </div>
      </div>
    </div>
    <div class="nav" v-if="user_type == 1">
      <div @click="gonav('inquirylist')">
        <img src="../../assets/img/inquiry_1.png" alt="" />
        <p>问诊</p>
      </div>
      <div>
        <img src="../../assets/img/inquiry_2.png" alt="" />
        <p>复诊</p>
      </div>
      <div>
        <img src="../../assets/img/inquiry_3.png" alt="" />
        <p>病患管理</p>
      </div>
    </div>
    <div class="not" v-if="user_type == 1">
      <div class="h2">病患消息</div>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="item in list" @click="gonav('inquirying')" class="mylist" :key="item">
            <div class="listtop">
              <div class="cl">
                <img src="https://img.yzcdn.cn/vant/apple-1.jpg" class="fl" alt="" />
                <div class="fl">
                  <p>王林</p>
                  <p class="em">男 46岁</p>
                </div>
              </div>
              <div class="top_right">
                <span class="em">3分钟前</span>
                <span class="zt">待接诊</span>
              </div>
            </div>
            <div class="listbot sl">
              <span class="h1">病情描述:</span
              >最近一个月总是头晕、头痛、疲劳、心悸等，有时还会出现注意力不集中、记忆力减退、肢体麻木的现象
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="general" v-if="user_type == 2">
      <div class="top_1 mb8">
        <div @click="gonav('hospitallist')">
          <img src="../../assets/img/inquiry_4.png" alt="" />
          <span>找医生</span>
        </div>
        <div @click="gonav('hospitallist')">
          <img src="../../assets/img/inquiry_5.png" alt="" />
          <span>复诊/开药</span>
        </div>
        <div @click="gonav('privatedoctor')">
          <img src="../../assets/img/inquiry_6.png" alt="" />
          <span>私人医生</span>
        </div>
      </div>
      <div class="top_2">
        <div @click="gonav('ambulance')"> 
          <img src="../../assets/img/inquiry_7.png" alt="" />
          <span>救护车</span>
        </div>
        <div @click="gonav('Quickmatch')"> 
          <img src="../../assets/img/inquiry_8.png" alt="" />
          <span>药品急配</span>
        </div>
        <div>
          <img src="../../assets/img/inquiry_9.png" alt="" />
          <span>档案</span>
        </div>
        <div @click="gonav('fund')"> 
          <img src="../../assets/img/inquiry_10.png" alt="" />
          <span>基金</span>
        </div>
      </div>
      <div class="banner">asdasd</div>
      <van-tabs class="mytab"  sticky  color="#1DAEFC" line-height="6" line-width="60"
              animated> 
        <van-tab title="慢性调理">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
            >
              <div v-for="item in list" @click="gonav('article')" class="newlist" :key="item" >
                <div class="leftdiv">
                  <h3 class="sl">社区团购，能成长出万亿级平台吗？</h3>
                  <div class="leftbot">
                    <span>#慢病条例</span>
                    <img src="../../assets/img/yanj.png" />
                    <em>456人已阅读</em>
                  </div>
                </div>
                <img src="../../assets/img/inquiry_bg.png" alt="" class="rightimg" />
              </div>
            </van-list>
          </van-pull-refresh>
        </van-tab>
        <van-tab title="儿童专题">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
            >
              <div v-for="item in list" @click="gonav('article')" class="newlist" :key="item">
                <div class="leftdiv">
                  <h3 class="sl">社区团购，能成长出万亿级平台吗？</h3>
                  <div class="leftbot">
                    <span>#慢病条例</span>
                    <img src="../../assets/img/yanj.png" />
                    <em>456人已阅读</em>
                  </div>
                </div>
                <img src="../../assets/img/inquiry_bg.png" alt="" class="rightimg" />
              </div>
            </van-list>
          </van-pull-refresh>
        </van-tab>
        <van-tab title="女性健康">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
            >
              <div v-for="item in list" @click="gonav('inquirying')" class="newlist" :key="item">
                <div class="leftdiv">
                  <h3 class="sl">社区团购，能成长出万亿级平台吗？</h3>
                  <div class="leftbot">
                    <span>#慢病条例</span>
                    <img src="../../assets/img/yanj.png" />
                    <em>456人已阅读</em>
                  </div>
                </div>
                <img src="../../assets/img/inquiry_bg.png" alt="" class="rightimg" />
              </div>
            </van-list>
          </van-pull-refresh>
        </van-tab>
        <van-tab title="皮肤疾病">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
            >
              <div v-for="item in list" @click="gonav('inquirying')" class="newlist" :key="item">
                <div class="leftdiv">
                  <h3 class="sl">社区团购，能成长出万亿级平台吗？</h3>
                  <div class="leftbot">
                    <span>#慢病条例</span>
                    <img src="../../assets/img/yanj.png" />
                    <em>456人已阅读</em>
                  </div>
                </div>
                <img src="../../assets/img/inquiry_bg.png" alt="" class="rightimg" />
              </div>
            </van-list>
          </van-pull-refresh>
        </van-tab>
      </van-tabs>
    </div>
    <navbar :active="1"></navbar>
  </div>
</template>
<script>
import navbar from '@/components/common/nav'
export default {
  components:{
    navbar
  },
  data() {
    return {
      active: "",
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      user_type: 2,
    };
  },
  mounted() {},
  methods: {
    gonav(url){
      this.$router.push({ name: url });
    },
    // // 去问诊列表
    // goinquirylist() {
    //   this.$router.push({ name: "inquirylist" });
    // },

    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }

        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        this.loading = false;

        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  },
};
</script>

<style lang="less" scoped>
.bg {
  width: 375px;
  text-align: center;
  .general {
    .top_1 {
      height: 162px;
      background: url("../../assets/img/inquiry_bg.png");
      background-size: 100% 100%;
      display: flex;
      padding: 32px 16px;
      justify-content: space-between;
      div {
        width: 109px;
        height: 84px;
        background: #fff;
        color: #2d2d2d;
        border-radius: 8px;
        font-weight: 700;
        img {
          width: 48px;
          height: 48px;
          display: block;
          margin: 0 auto;
        }
      }
    }
    .top_2 {
      width: 343px;
      margin: 0 auto;
      background: #fff;
      padding: 16px;
      justify-content: space-between;
      display: flex;
      div {
        width: 109px;
        height: 84px;
        background: #fff;
        color: #2d2d2d;
        border-radius: 8px;
        font-weight: 400;
        span {
          margin-top: 8px;
          display: block;
        }
        img {
          width: 48px;
          height: 48px;
          display: block;
          margin: 0 auto;
        }
      }
    }
    .banner {
      width: 343px;
      height: 80px;
      margin: 0 auto;
      background: #fff;
      margin-top: 8px;
    }
  }
  .mytab {
    margin-top: 8px;
  }
  .newlist {
    padding: 16px;
    background: #fff;
    display: flex;
    .leftdiv {
      width: 215px;
      text-align: left;
      position: relative;
      h3 {
        font-size: 16px;
        color: #2d2d2d;
      }
      .leftbot {
        position: absolute;
        bottom: 0;
        span {
          color: #1daefc;
          margin-right: 25px;
        }
        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          vertical-align: top;
        }
        em {
          font-style: normal;
          color: #d8d8d8;
        }
      }
    }
    .rightimg {
      width: 120px;
      height: 90px;
      border-radius: 8px;
    }
  }
}
.not {
  margin-top: 8px;

  background: #fff;
  .h2 {
    font-size: 14px;
    padding: 16px;
    font-weight: 700;
    text-align: left;
    color: #2d2d2d;
  }
}
.nav {
  height: 110px;
  background: #fff;
  padding: 16px;
  display: flex;
  justify-content: space-evenly;
  text-align: center;

  img {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  p {
    color: #2d2d2d;
    font-size: 14px;
  }
}
.top {
  font-size: 14px;
  background: #1daefc;
  height: 194px;
  width: 375px;
  color: #fff;
  .top_1 {
    padding: 16px 16px 19px 17px;
    text-align: left;
    border-bottom: 2px solid #4cbffd;
    height: 115px;
    p {
      margin-bottom: 6px;
      .f18 {
        font-weight: 700;
        margin-right: 16px;
      }
      .tag {
        display: inline-block;
        font-size: 12px;
        text-align: center;
        padding: 5px 10px;

        background: #4cbffd;
        border-radius: 6px;
      }
    }

    img {
      display: block;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 2px solid #fff;
      margin-right: 16px;
    }
  }
  .top_2 {
    padding: 16px;
    .bor {
      border-right: 2px solid #4cbffd;
    }
    .fl {
      width: 50%;
    }
  }
}
.mylist {
  .listbot {
    font-size: 14px;
    text-align: left;
    margin-top: 15px;
    border-bottom: 1px solid #efefef;
    padding-bottom: 15px;
  }
  .top_right {
    .em {
      margin-right: 15px;
    }
    .zt {
      font-size: 14px;
      color: rgba(29, 174, 252, 1);
    }
    .zt.on {
      font-size: 14px;
      color: #d8d8d8;
    }
  }
  width: 100%;
  background: #ffffff;
  height: 154px;
  // margin-bottom: 10px;
  box-sizing: border-box;
  padding: 16px;
  .listtop {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    p {
      text-align: left;
      margin-bottom: 5px;
    }
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
}
</style>
